@import "../../variables.scss";

.notMasterLabel {
  position: fixed;
  top: 0;
  left: 0;
  opacity: .5;
  background-color: $red;
  color: $white;
  z-index: $env-label-index;
}
