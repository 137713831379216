@import "./variables.scss";

body {
  margin: 0;
  padding: 0;
  font-family: 'Prompt', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  display: none;
}

// a:hover {
//   background: none;
//   background-color: none;
// }

* { 
  -webkit-tap-highlight-color: transparent;
}



// @media (min-width: 320px) and (max-width: 480px) {
//   ::-webkit-scrollbar {
//     display: none;
//   }
// }