@charset "utf-8";
@import "./variables.scss";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Prompt');

// Update Bulma's global variables
$family-sans-serif: 'Prompt',
sans-serif;
$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $primary-color;
$tabs-boxed-link-active-background-color: $secondary-color;
$tabs-link-active-color: $white;
// $tabs-link-color: $black;
$tabs-link-active-border-bottom-color : $primary-color;
$tabs-boxed-link-active-border-color : $primary-color;
$tabs-border-bottom-color: $primary-color;
$tabs-boxed-link-radius: 0;
// $link: $pink;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Import only what you need from Bulma
@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/base/_all.sass";
@import "../node_modules/bulma/sass/elements/_all.sass";
@import "../node_modules/bulma/sass/form/_all.sass";
@import "../node_modules/bulma/sass/components/_all.sass";
@import "../node_modules/bulma/sass/grid/_all.sass";
@import "../node_modules/bulma/sass/layout/_all.sass";
@import 'bulma';
@import '~bulma-slider';