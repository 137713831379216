// set color
$set-color-1: #F3AD22;
$set-color-2: #334755;
$set-color-3: #BDC63F;
$set-color-4: #A97C50;
$set-color-5: #414042;
$set-red: #EF394E;
$set-green: #8DC63F;
// color
$primary-color: #F4AD24;
$lighter-primary-color: #FFB92F;
$secondary-color: #324755;
$white: #FEFEFE;
$black: #010101;
$red: hsl(348, 100%, 61%);
$green: green;
$gray: gray;
$purple: #8A4D76;
$pink: #FA7C91;
$brown: #757763;
$beige-light: #D0D1CD;
$beige-lighter: #EFF0EB;
$success-color: hsl(141, 71%, 48%);
$danger-color: hsl(348, 100%, 61%);
// std size
$xxs-px: 1px;
$xs-px: 3px;
$sm-px: 5px;
$md-px: 10px;
$smd-px: 15px;
$lg-px: 20px;
$xlg-px: 25px;
$xxlg-px: 30px;
$x-px: 40px;
$xx-px: 50px;

$md-radius: 10px;
// z-index
$float-index: 200;
$nav-index: 300;
$modal-index: 400;
$noti-index: 500;
$env-label-index: 600;

// float
$floathorizontalPosition: 20px;
$floatBaseVerticalPosition: 80px;
$floatSubVerticalPosition: 20px;
$floatBtnWidth: 60px;
$floatBtnHeight: 60px;

// nave
$top-nav-height: 55px;
$m-book-label-height: 40px;
$m-top-nav-height: 55px;
$m-bottom-nav-height: 60px;